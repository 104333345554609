<template>
  <transition-group name="fade-transition">
    <!-- main container -->
    <v-container class="cmpbk" id="search-logs-container" :key="1">
      <v-app-bar
        elevation="4"
        dark
        class="backdrop lighten-1"
        style="z-index: 2"
        height="60px"
        extension-height="75px"
      >
        <v-spacer />
        <v-toolbar-title> Search Logs </v-toolbar-title>
        <v-spacer />

        <template v-slot:extension>
          <div style="width: 100%">
            <v-text-field
              v-model="search_form.search_term"
              color="secondary"
              clearable
              filled
              outlined
              dark
              dense
              hide-details
              @keydown.enter="getSearchData"
              append-icon="$send"
              @click:append="getSearchData"
              label="Search logs"
              ref="search_field"
              prepend-inner-icon="$magnify"
            >
              <template v-slot:append-outer>
                <v-tooltip
                  bottom
                  :disabled="$vuetify.breakpoint.xs"
                  class="pb-4"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      light
                      class="px-4"
                      @click="openSearchDialog()"
                      color="white"
                      v-bind="attrs"
                      v-on="on"
                      style="top: -12px"
                    >
                      <v-icon v-if="search_filters_text.length == 0" size="30"
                        >$textSearch</v-icon
                      >
                      <v-icon v-else color="secondary" size="30"
                        >$textSearch</v-icon
                      >
                    </v-btn>
                  </template>
                  <span>Use Advanced Search</span>
                </v-tooltip>
              </template>
            </v-text-field>
            <div style="margin-top: -20px">
              <div
                v-if="search_filters_text.length > 0"
                class="pa-2 d-flex text--accent-1"
              >
                <div
                  v-if="search_filters_text.length == 1"
                  class="d-inline-flex"
                  style="max-width: 100%; flex-wrap: wrap"
                >
                  <div
                    v-for="(text, index) in search_filters_text"
                    :key="index"
                    class="mr-2 text-caption"
                  >
                    {{ text.title }}
                    <span class="filtertext">({{ text.value }})</span>
                  </div>
                </div>

                <div
                  v-else
                  class="d-inline-flex"
                  style="max-width: 100%; flex-wrap: wrap"
                >
                  <div
                    v-for="(text, index) in search_filters_text"
                    :key="index"
                    class="mr-2 text-caption"
                  >
                    {{ text.title }}
                    <span class="filtertext mr-1">({{ text.value }}) </span> |
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </v-app-bar>

      <v-card
        class="transparent pa-3"
        style="
          min-height: 600px;
          margin-top: -15px;
          padding-bottom: 60px !important;
          box-shadow: none;
        "
      >
        <!-- Logs -->
        <div
          v-for="(divisionGroup, index) in search_result"
          :key="index"
          class="my-4 search_results"
        >
          <h4
            class="py-1 mb-2"
            style="border-bottom: 1px solid grey; border-top: 1px solid grey"
          >
            {{ getDivisionDisplayName(divisionGroup.divisionID) }}
          </h4>
          <v-divider></v-divider>
          <logsgroup
            :ref="`divisionGroupRef${index}`"
            :groupIndex="index"
            :AllSelectedLogIds="divisionGroup.allSelectedLogIds"
            :log_groups="divisionGroup.logGroups"
            @logSelectionChange="logSelectionsChanged"
            @lazyRenderLogs="lazyRenderLogs"
            :highlightTerm="last_search_term"
          ></logsgroup>
        </div>
        <h3
          v-if="search_result.length == 0"
          style="text-align: center"
          class="mt-12"
        >
          No search results found
        </h3>
      </v-card>
    </v-container>

    <!-- Log FABS -->
    <logsfabs
      :AllSelectedLogIds="AllselectedIds"
      :persistentHome="true"
      @editlogs="editlogs()"
      @deletelog="deletelog()"
      @printLogs="printLogs()"
      @emailLogs="openEmailLogsDialog()"
      @editWebDetails="openWebDialog()"
      @openHelp="openHelpDialog"
      @copyLogs="openCopyLogsDialog()"
      :key="2"
    ></logsfabs>

    <!-- dialog container -->
    <div :key="3">
      <!-- Logs Dialog -->
      <logsdialog
        v-if="logs_dialog"
        @closeDialog="closeLogsDialog"
        :mode="logs_dialog_mode"
        :logEntryIDs="logs_dialog_logEntryIDs"
      ></logsdialog>

      <!-- Logs Web Dialog -->
      <logsWebDialog
        v-if="logs_web_dialog"
        @closeDialog="closeWebDialog"
        :logEntryIDs="logs_dialog_logEntryIDs"
      ></logsWebDialog>

      <!-- Logs Email Dialog -->
      <logsemaildialog
        v-if="logs_email_dialog"
        @closeDialog="closeLogsEmailDialog"
        :logEntryIDs="logs_dialog_logEntryIDs"
      ></logsemaildialog>

      <!-- Logs Copy Dialog -->
      <logsCopyDialog
        v-if="logs_copy_dialog"
        @closeDialog="closeLogsCopyDialog"
        :logEntryIDs="logs_dialog_logEntryIDs"
      ></logsCopyDialog>

      <!-- SEARCH dialog -->
      <searchDialog
        v-if="search_dialog"
        :search_form="search_form"
        @closeDialog="closeSearchDialog()"
        @filterTextUpdated="searchFiltersUpdated"
        @applyFilters="applySearchFilter()"
        @resetFilters="resetSearchFilters()"
      ></searchDialog>

      <!-- Help dialog -->
      <helpDialog
        v-if="help_dialog"
        helpID="ffd7b0f1-3dbe-4050-85c0-898adefecb0d"
        @closeDialog="closeHelpDialog()"
      >
      </helpDialog>
    </div>

    <v-overlay :key="4" :value="overlay_loading">
      <v-progress-circular
        indeterminate
        :size="80"
        :width="7"
        color="secondary"
      ></v-progress-circular>
    </v-overlay>
  </transition-group>
</template>

<style lang="scss" scoped>
@import "../../scss/site.scss";

#search-logs-container {
  max-width: 1100px;
}

.search-fab-container {
  display: flex;
  justify-content: center;
}

.filtertext {
  color: var(--v-secondary-base);
}
</style>

<script>
/* eslint-disable */
import { bus_common } from "../../mixins/bus_common";
import searchDialog from "./logs_search_dialog.vue";
import logsdialog from "./logs_dialog.vue";
import logsfabs from "./logs_fabs.vue";
import logsgroup from "./logs_group.vue";
import logsemaildialog from "./logs_email_dialog.vue";
const logsCopyDialog = () => import("./logs_copy_dialog.vue");
const logsWebDialog = () => import("./logs_web_dialog.vue");
const helpDialog = () => import("../../components/help_dialog.vue");
export default {
  name: "logs_search",
  mixins: [bus_common],
  components: {
    searchDialog,
    logsdialog,
    logsfabs,
    logsgroup,
    logsemaildialog,
    logsCopyDialog,
    helpDialog,
    logsWebDialog,
  },
  props: {
    prop_search_form: {
      type: Object,
      default() {
        return {
          logtype: "ALL",
          search_term: "",
          logEntryIDs: [],
          start_date: "",
          end_date: "",
        };
      },
    },
  },
  data() {
    return {
      search_form: this.prop_search_form,
      const_search_form: {
        logtype: "ALL",
        search_term: "",
        logEntryIDs: [],
        start_date: "",
        end_date: "",
      },
      last_search_term: "",
      search_filters_text: [],
      search_dialog: false,
      AllSelectedLogIds: [],
      logs_dialog: false,
      logs_dialog_mode: "edit",
      logs_dialog_logEntryIDs: [],

      search_result: [],
      overlay_loading: false,

      help_dialog: false,
      logs_email_dialog: false,
      logs_web_dialog: false,
      logs_copy_dialog: false,
    };
  },
  methods: {
    message1() {
      alert("This button does nothing yet.");
    },
    init() {
      // On init, we may have logIds are part of the URL
      // We should set that as part of the search form only once on init
      var logEntries = this.$route.query.logEntries;
      if (logEntries != undefined) {
        this.search_form.logEntryIDs = this.stringToIntegerArray(logEntries);
      }

      this.generate_filter_text();
      this.getSearchData();

      // After the first search, clear Ids
      this.search_form.logEntryIDs = [];
    },
    stringToIntegerArray(inputString) {
      // Split the input string by commas to get an array of strings.
      const idStrings = inputString.split(",");

      // Initialize an empty array to store the parsed integer values.
      const idArray = [];

      // Loop through the idStrings and parse them into integers.
      for (const idString of idStrings) {
        // Use parseInt to convert the string to an integer.
        const id = parseInt(idString, 10);

        // Check if the parsing was successful and the result is a valid integer.
        if (!isNaN(id) && Number.isInteger(id)) {
          idArray.push(id);
        }
      }

      return idArray;
    },
    openLogsDialog() {
      this.logs_dialog = true;
    },

    closeLogsDialog() {
      this.logs_dialog = false;
      this.getSearchData();
    },

    openHelpDialog() {
      this.help_dialog = true;
    },

    closeHelpDialog() {
      this.help_dialog = false;
    },

    // Email Logs Dialog functions
    openEmailLogsDialog() {
      this.logs_dialog_logEntryIDs = this.AllselectedIds;
      this.logs_email_dialog = true;
      setTimeout(() => {
        this.clearAllSelectedLogIds();
      }, 300);
    },
    closeLogsEmailDialog() {
      this.logs_email_dialog = false;
      this.getSearchData();
    },

    // Web details dialog functions
    openWebDialog() {
      this.logs_dialog_logEntryIDs = this.AllselectedIds;
      this.logs_web_dialog = true;
      setTimeout(() => {
        this.clearAllSelectedLogIds();
      }, 300);
    },

    closeWebDialog() {
      this.logs_web_dialog = false;
      this.getSearchData();
    },

    // Copy Logs Dialog functions
    openCopyLogsDialog() {
      this.logs_dialog_logEntryIDs = this.AllselectedIds;
      this.logs_copy_dialog = true;
      setTimeout(() => {
        this.clearAllSelectedLogIds();
      }, 300);
    },
    closeLogsCopyDialog() {
      this.logs_copy_dialog = false;
      this.getSearchData();
    },
    editlogs() {
      this.logs_dialog_mode = "edit";
      this.logs_dialog_logEntryIDs = this.AllselectedIds;
      this.openLogsDialog();
      setTimeout(() => {
        this.clearAllSelectedLogIds();
      }, 300);
    },
    async deletelog() {
      // Kinda a mess sorry
      this.overlay_loading = true;

      // We can only delete a single record
      let LogIDToDelete = this.AllselectedIds[0];
      // Find the log record based on the selected id
      var LogToDelete = this.getLogEntry(LogIDToDelete);
      if (LogToDelete == null) {
        this.overlay_loading = false;
        return;
      }
      // Set all s_Del to t
      LogToDelete.s_del = "t";
      for (let i = 0; i < LogToDelete.partiesInvolved.length; i++) {
        LogToDelete.partiesInvolved[i].s_del = "t";
      }

      var form = { token: this.$store.state.user.token, logEntry: LogToDelete };
      await this.$axios
        .post("/api/logs/SaveLog", form)
        .then((response) => {
          this.swall_it(
            "Saved!",
            "The log entry was successfully deleted.",
            "success",
            null,
            null,
            null
          );
          this.getSearchData();
        })
        .catch((error) => {
          this.overlay_loading = false;
          this.hande_swal_error(error, null);
        });
    },
    getLogEntry(logEntryID) {
      // GRoss
      for (let i = 0; i < this.search_result.length; i++) {
        const result = this.search_result[i];
        for (let j = 0; j < result.logGroups.length; j++) {
          const logGroup = result.logGroups[j];
          for (let k = 0; k < logGroup.logs.length; k++) {
            const log = logGroup.logs[k];
            if (log.logEntryID == logEntryID) {
              return log;
            }
          }
        }
      }

      return null;
    },
    printLogs() {
      var LogEntries = this.integerArrayToString(this.AllselectedIds);
      var qry_token = this.$route.query.token;
      this.$router.push({
        name: "logs-print",
        query: { logEntries: LogEntries, token: qry_token },
      });
    },
    integerArrayToString(array) {
      // Filter out any non-integer values and convert the integers to strings.
      const idStrings = array
        .filter((value) => Number.isInteger(value))
        .map((value) => value.toString());

      // Join the array of strings with commas to create the final string.
      const resultString = idStrings.join(",");

      return resultString;
    },
    // Search Dialog functions
    openSearchDialog() {
      this.search_dialog = true;
      // Icon, re-focuses search bar, this prevents that
      this.$refs.search_field.blur();
    },
    closeSearchDialog() {
      this.search_dialog = false;
      // Icon, re-focuses search bar, this prevents that
      this.$refs.search_field.blur();
    },
    searchFiltersUpdated(data) {
      this.search_filters_text = data;
    },
    applySearchFilter() {
      this.closeSearchDialog();
      this.getSearchData();
    },
    resetSearchFilters() {
      this.search_form = JSON.parse(JSON.stringify(this.const_search_form));
    },
    generate_filter_text() {
      let filters_active_text = [];

      if (this.search_form.logtype != "") {
        let filter_text = {
          title: "LOG TYPE",
          value: this.search_form.logtype,
        };
        filters_active_text.push(filter_text);
      }

      if (
        this.search_form.start_date != "" ||
        this.search_form.end_date != ""
      ) {
        let sdate = "mm/dd/yyyy";
        let edate = "mm/dd/yyyy";

        if (this.search_form.start_date != "")
          sdate = this.$moment(
            new Date(this.search_form.start_date.replace(/-/g, "/"))
          ).format("MM/DD/YYYY");
        if (this.search_form.end_date != "")
          edate = this.$moment(
            new Date(this.search_form.end_date.replace(/-/g, "/"))
          ).format("MM/DD/YYYY");

        let filter_text = { title: "DATE RANGE", value: sdate + " - " + edate };
        filters_active_text.push(filter_text);
      }
      this.searchFiltersUpdated(filters_active_text);
    },
    SearchLogs() {},

    async getSearchData() {
      this.overlay_loading = true;
      this.clearAllSelectedLogIds();
      let search_copy = this.search_form;

      if (search_copy.start_date == "") {
        search_copy.start_date = "1900-01-01";
      }

      if (search_copy.end_date == "") {
        search_copy.end_date = "1900-01-01";
      }

      var form = {
        token: this.$store.state.user.token,
        searchForm: search_copy,
      };

      await this.$axios
        .post("/api/logs/SearchLogs", form)
        .then((response) => {
          this.last_search_term = search_copy.search_term;

          this.search_result = response.data;

          // Lazy load the data of the last opened panels
          // Iterate over refs and call the function in each child component
          for (let index = 0; index < this.search_result.length; index++) {
            const childRef = this.$refs[`divisionGroupRef${index}`][0];
            if (childRef) {
              childRef.LazyLoadOpenedPanel();
            }
          }

          this.overlay_loading = false;
        })
        .catch((error) => {
          this.hande_swal_error(error, this.swal_target);
          this.overlay_loading = false;
        });
    },

    // log groups func
    logSelectionsChanged(data) {
      this.search_result[data.groupIndex].allSelectedLogIds = data.Ids;
    },

    getDivisionDisplayName(id) {
      if (this.divisions_list != null) {
        const div = this.divisions_list.find((obj) => obj.logDivisionID == id);
        // Check if an object was found
        if (div !== undefined) {
          return div.displayName;
        } else {
          // Return null or any default value if the object is not found
          return "";
        }
      }
    },

    clearAllSelectedLogIds() {
      for (let i = 0; i < this.search_result.length; i++) {
        this.search_result[i].allSelectedLogIds = [];
      }
    },

    async lazyRenderLogs(data) {
      if (
        this.search_result[data.groupIndex].logGroups[data.index]
          .logs_rendered == false
      ) {
        //console.log("Lazy render data now ...");

        // In order to lazy render the data, we must get all IDs in this group
        let logIds = this.getPropertyValues(
          this.search_result[data.groupIndex].logGroups[data.index].logs,
          "logEntryID"
        );

        var form = {
          token: this.$store.state.user.token,
          logEntryIDs: logIds,
          s_del: "f",
          dateGrouping: false,
        };

        await this.$axios
          .post("/api/logs/GetByList", form)
          .then((response) => {
            const fieldsToCopy = ["description", "partiesInvolved"];
            this.copyGroupFields(
              data.groupIndex,
              data.index,
              response.data,
              fieldsToCopy
            );
            this.search_result[data.groupIndex].logGroups[
              data.index
            ].logs_rendered = true;
          })
          .catch((error) => {
            this.hande_swal_error(error, this.swal_target);
            this.search_result[data.groupIndex].logGroups[
              data.index
            ].logs_rendered = false;
          });
      } else {
        //console.log("Lazy data already rendered skipping ....");
      }
    },

    getPropertyValues(arr, propertyName) {
      // Check if the array is not empty and the property name is provided
      if (!arr || arr.length === 0 || !propertyName) {
        console.error("Invalid input");
        return [];
      }

      // Use map to extract the specified property directly
      return arr.map((obj) => obj[propertyName] || 0);
    },
    copyGroupFields(groupIndex, i, dataArray, fieldsToCopy) {
      // For each log we get back, let's copy the fields over
      for (let j = 0; j < dataArray.length; j++) {
        const logToCopy = dataArray[j];

        // Find the index of the matching log
        let logIndex = this.search_result[groupIndex].logGroups[
          i
        ].logs.findIndex((obj) => obj.logEntryID === logToCopy.logEntryID);

        fieldsToCopy.forEach((field) => {
          this.search_result[groupIndex].logGroups[i].logs[logIndex][field] =
            logToCopy[field];
        });
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$store.state.user.token != "") {
        this.init();
      }
    });
  },
  computed: {
    divisions_list() {
      if (this.$store.state.logDivisions.length > 0) {
        return this.$store.state.logDivisions;
      } else {
        return null;
      }
    },
    AllselectedIds() {
      if (this.search_result.length == 0) {
        return [];
      } else {
        const flattenedArray = [].concat(
          ...this.search_result.map((obj) => obj.allSelectedLogIds)
        );
        return flattenedArray;
      }
    },
  },
  watch: {
    "$store.state.user.token": function () {
      if (this.$store.state.user.token != "") {
        this.init();
      }
    },
  },
};
</script>
