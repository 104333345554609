<template>
  <!-- FAB -->
  <div class="fab-container">
    <v-fab-transition>
      <v-btn
        v-show="AllSelectedLogIds.length >= 1"
        v-if="$store.state.EWCanEdit"
        color="secondary black--text"
        :disabled="AllSelectedLogIds.length > 10"
        elevation="5"
        class="mb-6"
        dark
        bottom
        fab
        @click="editlogs()"
      >
        <v-icon>$pencil</v-icon>
      </v-btn>
    </v-fab-transition>

    <v-fab-transition>
      <v-btn
        v-if="persistentHome"
        color="secondary black--text"
        dark
        fab
        @click="goHome()"
      >
        <v-icon> $home </v-icon>
      </v-btn>
    </v-fab-transition>

    <v-fab-transition>
      <v-btn
        v-if="persistentAdd && $store.state.EWCanAdd"
        color="secondary black--text"
        elevation="5"
        class="mb-6"
        dark
        bottom
        fab
        @click="addLog()"
      >
        <v-icon>$plus</v-icon>
      </v-btn>
    </v-fab-transition>

    <v-fab-transition>
      <v-btn
        v-show="AllSelectedLogIds.length == 1"
        v-if="$store.state.EWCanEdit"
        color="red black--text lighten-1"
        elevation="5"
        @click="priorToDeleteLog()"
        class="mb-6"
        dark
        bottom
        fab
      >
        <v-icon>$del</v-icon>
      </v-btn>
    </v-fab-transition>

    <v-fab-transition>
      <v-speed-dial v-model="menuFAB">
        <template v-slot:activator>
          <v-btn color="primary black--text" class="mb-6" dark fab>
            <v-icon v-if="menuFAB"> $close </v-icon>
            <v-icon v-else> $menu </v-icon>
          </v-btn>
        </template>

        <v-btn
          color="red lighten-1 black--text"
          dark
          fab
          @click="priorToLogOut()"
        >
          <v-icon> $logout </v-icon>
        </v-btn>

        <v-btn
          v-if="$store.state.NWRole"
          v-show="AllSelectedLogIds.length >= 1"
          :disabled="AllSelectedLogIds.length > 10"
          color="pink black--text lighten-1"
          dark
          fab
          @click="priorToEditWebDetails()"
        >
          <v-icon size="28"> $news </v-icon>
        </v-btn>

        <v-btn
          v-if="$store.state.EWCanEdit && $store.state.EWCanAdd"
          v-show="AllSelectedLogIds.length >= 1"
          :disabled="AllSelectedLogIds.length > 10"
          color="orange black--text lighten-1"
          dark
          fab
          @click="priorToCopyLogs()"
        >
          <v-icon> $copy </v-icon>
        </v-btn>

        <v-btn
          v-show="AllSelectedLogIds.length >= 1"
          color="blue black--text lighten-1"
          dark
          fab
          @click="priorToPrintView()"
        >
          <v-icon> $printeye </v-icon>
        </v-btn>

        <v-btn
          v-show="AllSelectedLogIds.length >= 1"
          color="purple black--text lighten-1"
          dark
          fab
          @click="priorToEmail()"
        >
          <v-icon> $email </v-icon>
        </v-btn>

        <v-btn color="green black--text lighten-1" dark fab @click="openHelp()">
          <v-icon> $help </v-icon>
        </v-btn>

        <v-btn
          v-if="!persistentHome"
          color="secondary black--text"
          dark
          fab
          @click="goHome()"
        >
          <v-icon> $home </v-icon>
        </v-btn>
      </v-speed-dial>
    </v-fab-transition>
  </div>
</template>

<style lang="scss" scoped>
.fab-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  column-gap: 15px;
  position: fixed;
  bottom: 0;
  // left: 12vw;
  // right: 10vw;
  width: 100%;
  z-index: 2;
}
</style>

<script>
/* eslint-disable */
import { bus_common } from "../../mixins/bus_common";
export default {
  name: "logs_fabs",
  mixins: [bus_common],
  props: {
    AllSelectedLogIds: {
      type: Array,
      default: [],
    },
    persistentHome: {
      type: Boolean,
      default: true,
    },
    persistentAdd: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menuFAB: null,
    };
  },
  methods: {
    init() {},
    priorToDeleteLog() {
      let callback = () => {
        this.deleteLog();
      };
      this.swall_diag(
        "Delete Log?",
        `Are you sure you would like to delete this log?`,
        "question",
        callback,
        "Yes",
        "No",
        null,
        null
      );
    },
    deleteLog() {
      this.$emit("deletelog");
    },
    addLog() {
      this.$emit("addlog");
    },
    priorToCopyLogs() {
      let callback = () => {
        this.copyLogs();
      };
      this.swall_diag(
        "Copy log(s)?",
        `Are you sure you would like to copy these ${this.AllSelectedLogIds.length} log(s)?`,
        "question",
        callback,
        "Yes",
        "No",
        null,
        null
      );
    },
    copyLogs() {
      this.$emit("copyLogs");
    },
    priorToLogOut() {
      let callback = () => {
        this.logOut();
      };
      this.swall_diag(
        "Logout?",
        `Are you sure you would like to logout?`,
        "question",
        callback,
        "Yes",
        "No",
        null,
        null
      );
    },
    logOut() {
      window.location.replace(this.$store.state.loginURL);
    },
    priorToPrintView() {
      let callback = () => {
        this.printViewLogs();
      };
      this.swall_diag(
        "Print/view log(s)?",
        `Are you sure you would like to print/view these ${this.AllSelectedLogIds.length} log(s)?`,
        "question",
        callback,
        "Yes",
        "No",
        null,
        null
      );
    },
    printViewLogs() {
      this.$emit("printLogs");
    },
    priorToEmail() {
      let callback = () => {
        this.emailLogs();
      };
      this.swall_diag(
        "Email log(s)?",
        `Are you sure you would like to email these ${this.AllSelectedLogIds.length} log(s)?`,
        "question",
        callback,
        "Yes",
        "No",
        null,
        null
      );
    },
    emailLogs() {
      this.$emit("emailLogs");
    },

    priorToEditWebDetails() {
      let callback = () => {
        this.editWebDetails();
      };
      this.swall_diag(
        "Edit Web Details?",
        `Are you sure you would like to edit the web details of these ${this.AllSelectedLogIds.length} log(s)?`,
        "question",
        callback,
        "Yes",
        "No",
        null,
        null
      );
    },
    editWebDetails() {
      this.$emit("editWebDetails");
    },

    openHelp() {
      this.$emit("openHelp");
    },

    editlogs() {
      this.$emit("editlogs");
    },

    goHome() {
      var qry_token = this.$route.query.token;
      this.$router.push({ name: "home-logs", query: { token: qry_token } });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.init();
    });
  },
};
</script>
