<template>
  <v-expansion-panels v-model="openedLogPanel">
    <v-expansion-panel
      v-for="(group, i) in log_groups"
      class="backdrop"
      :key="i"
      @click="LazyRenderData(i, $event)"
    >
      <v-expansion-panel-header class="group_header">
        <v-row align="center" dense no-gutters>
          <v-checkbox
            :input-value="AllLogsSelected(group)"
            :indeterminate="SomeLogsSelected(group)"
            on-icon="$checkallon"
            hide-details
            off-icon="$checkalloff"
            indeterminate-icon="$checkallsome"
            @click="SelectAllLogs($event, group)"
            :disabled="group.logs.length == 0"
            style="max-width: fit-content; max-height: 30px; margin: 0px"
            color="secondary"
            class="checkbox-expansion pr-1"
          >
          </v-checkbox>

          <div>
            <div style="display: inline-flex">
              <h4
                class="white--text"
                style="width: min-content; white-space: nowrap"
              >
                {{ formatDate(group.date) }} ({{ group.logs.length }} logs)
                &nbsp;
              </h4>
              <v-slide-x-transition>
                <h4
                  v-if="group.logs_selected.length > 0"
                  style="width: min-content; white-space: nowrap"
                  class="faded-card-text"
                >
                  ({{ group.logs_selected.length }})
                </h4>
              </v-slide-x-transition>
            </div>

            <div style="width: 100%" class="mt-1 faded-card-text">
              {{ getLastWeekDayOrDate(group.date) }}
            </div>
          </div>
        </v-row>
      </v-expansion-panel-header>

      <v-expansion-panel-content>
        <logsList
          @logSelectionChange="logSelectionChange"
          @dataExpandedAndRendered="OndataExpandedAndRendered"
          :logs_selected="group.logs_selected"
          :logs_data="group.logs"
          :logs_rendered="group.logs_rendered"
          :highlightTerm="highlightTerm"
          :ref="`loglist${i}`"
        >
        </logsList>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<style lang="scss">
.checkbox-expansion {
  .v-label {
    flex-wrap: wrap;
  }
}
</style>

<script>
/* eslint-disable */
import { bus_common } from "../../mixins/bus_common";
import logsList from "./logs_list.vue";
export default {
  name: "logsgroup",
  mixins: [bus_common],
  components: { logsList },
  props: ["log_groups", "AllSelectedLogIds", "groupIndex", "highlightTerm"],
  watch: {},
  data() {
    return {
      openedLogPanel: undefined,
    };
  },
  methods: {
    AllLogsSelected(group) {
      // Check each log in the group and if they are all present, then all logs are selected
      // Otherwise if there is more than 1, it is intermeidate
      // If none, then none are selected
      if (group.logs_selected.length == 0) return false;

      const intersection = group.logs.filter((item) =>
        group.logs_selected.includes(item)
      );

      if (
        intersection.length === group.logs.length &&
        intersection.length === group.logs_selected.length
      ) {
        return true; // All elements are present in both arrays
      } else if (intersection.length > 0) {
        return false; // Some elements are present in both arrays
      } else {
        return false; // No elements are present in both arrays
      }
    },
    SomeLogsSelected(group) {
      if (group.logs_selected.length == 0) return false;

      const intersection = group.logs.filter((item) =>
        group.logs_selected.includes(item)
      );

      if (
        intersection.length === group.logs.length &&
        intersection.length === group.logs_selected.length
      ) {
        return false; // All elements are present in both arrays
      } else if (intersection.length > 0) {
        return true; // Some elements are present in both arrays
      } else {
        return false; // No elements are present in both arrays
      }
    },
    SelectAllLogs(event, group) {
      // Prevent collapsible header from opening
      event.preventDefault();
      event.stopPropagation();

      const intersection = group.logs.filter((item) =>
        group.logs_selected.includes(item)
      );

      if (
        intersection.length === group.logs.length &&
        intersection.length === group.logs_selected.length
      ) {
        group.logs_selected = []; // All elements are present in both arrays, so clear selected
      } else if (intersection.length > 0) {
        group.logs_selected = [];
        group.logs_selected.push(...group.logs); // Some elements are present in both arrays
      } else {
        group.logs_selected = [];
        group.logs_selected.push(...group.logs); // No elements are present in both arrays
      }

      this.logSelectionChange();
    },
    preventChecking(event, i) {
      // Prevent label from checking checkbox but allow opening and closing of panel
      if (this.openedLogPanel == i) {
        this.openedLogPanel = undefined;
      } else {
        this.openedLogPanel = i;
      }
      // Prevent collapsible header from opening
      event.preventDefault();
      event.stopPropagation();
    },
    retrieveAllSelectedLogIds() {
      let Ids = [];
      for (let i = 0; i < this.log_groups.length; i++) {
        for (let j = 0; j < this.log_groups[i].logs_selected.length; j++) {
          const element = this.log_groups[i].logs_selected[j];
          if (!Ids.includes(element.logEntryID)) {
            Ids.push(element.logEntryID);
          }
        }
      }
      return Ids;
    },
    logSelectionChange() {
      if (this.groupIndex != undefined) {
        let payload = {
          Ids: this.retrieveAllSelectedLogIds(),
          groupIndex: this.groupIndex,
        };
        this.$emit("logSelectionChange", payload);
      } else {
        this.$emit("logSelectionChange", this.retrieveAllSelectedLogIds());
      }
    },
    getLastWeekDayOrDate(dateString) {
      const currentDate = this.$moment();
      const inputDate = this.$moment(new Date(dateString), "MM/DD/YYYY");
      const diffDays = currentDate.diff(inputDate, "days");

      if (diffDays <= 6 && diffDays >= 0) {
        return inputDate.format("dddd"); // Returns the name of the day of the week
      } else {
        return ""; // Returns the date in 'mm/dd/yyyy' format
      }
    },
    formatDate(date) {
      return this.$moment(new Date(date)).format("MM/DD/YYYY");
    },
    LazyLoadOpenedPanel() {
      if (this.openedLogPanel != undefined) {
        this.LazyRenderData(this.openedLogPanel);
      }

      // Force close all expanded logs?
      // // Access dynamic child components using refs
      // for (let index = 0; index < this.log_groups.length; index++) {
      //   const refKey = `loglist${index}`;
      //   const childComponent = this.$refs[refKey];

      //   // Call methods of each dynamic child component
      //   if (childComponent) {
      //     childComponent.childMethod();
      //   }
      // }
    },

    LazyRenderData(i, $event) {
      if (this.groupIndex != undefined) {
        let payload = {
          index: i,
          groupIndex: this.groupIndex,
        };
        this.$emit("lazyRenderLogs", payload);
      } else {
        this.$emit("lazyRenderLogs", i);
      }

      // This will highlight the headers of the logs timeout is for animation?
      this.$nextTick(() => {
        this.ScrollToRecord($event);
        setTimeout(() => {
          this.highlightResults(this.highlightTerm);
        }, 200);
      });
    },

    removeMarkings() {
      // Header information is always present therfore we should always remove
      // Inner data is dynamically rendered so it will clean itself up
      // Get all elements with <mark> tags under the logs title class
      var markedElements = document.querySelectorAll(".logs_title mark");
      // Loop through each marked element
      markedElements.forEach(function (element) {
        // Replace the <mark> tags with their text content
        element.outerHTML = element.textContent;
      });
    },

    highlightResults(searchTerm) {
      if (
        searchTerm == "" ||
        searchTerm == undefined ||
        this.highlightRunning ||
        searchTerm.length < 3
      )
        return;
      this.highlightRunning = true;
      //console.log("Running highlight func ...");
      // Remove previous markings
      this.removeMarkings();
      // Get all containers
      var containers = document.querySelectorAll(".logs_title");
      //console.log(containers);
      // Loop through each container
      containers.forEach((container) => {
        // Call the recursive function to search and highlight
        this.highlightInContainer(container, searchTerm);
      });
      this.highlightRunning = false;
    },

    highlightInContainer(element, searchTerm) {
      //console.log(element);
      // Base case: if the element is a text node, search for the term and wrap it with <mark> tags
      if (element.nodeType === Node.TEXT_NODE) {
        var regex = new RegExp("(" + searchTerm + ")", "gi");
        var replacement = element.textContent.replace(regex, "<mark>$1</mark>");
        element.parentNode.innerHTML = element.parentNode.innerHTML.replace(
          element.textContent,
          replacement
        );
      } else if (element.nodeType === Node.ELEMENT_NODE) {
        // Recursive case: if the element is an element node, search its child nodes
        element.childNodes.forEach((child) => {
          //console.log(child);
          this.highlightInContainer(child, searchTerm);
        });
      }
    },

    OndataExpandedAndRendered() {
      this.$emit("dataExpandedAndRendered");
    },

    ScrollToRecord(event) {
      if (event == undefined) return;
      const offset = 300;
      const targetClass = "v-expansion-panel-header";

      let scrollTarget = event.target;

      // Look for the target element upwards
      while (scrollTarget && !scrollTarget.classList.contains(targetClass)) {
        scrollTarget = scrollTarget.parentElement;
      }

      // Look for the target element downwards if not found upwards
      if (!scrollTarget) {
        scrollTarget = event.target;
        while (scrollTarget && !scrollTarget.classList.contains(targetClass)) {
          scrollTarget = scrollTarget.nextElementSibling;
        }
      }

      if (scrollTarget) {
        this.$nextTick(() => {
          setTimeout(() => {
            // Do not scroll if closed
            if (this.openedLogPanel != undefined) {
              scrollTarget.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            }
          }, 300);
        });
      }
    },

    init() {},
  },
  mounted() {
    // this.$nextTick(() => {
    //   this.init();
    // });
  },
};
</script>
