var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticStyle:{"max-width":"none"}},[_c('v-data-iterator',{attrs:{"items":_vm.logs_data,"no-results-text":'No logs found',"no-data-text":'No logs found',"item-key":"logEntryID","hide-default-footer":"","expanded":_vm.ExpandedItems,"disable-pagination":""},on:{"update:expanded":function($event){_vm.ExpandedItems=$event}},scopedSlots:_vm._u([{key:"default",fn:function({ items, isExpanded, expand }){return _vm._l((items),function(log,index){return _c('v-row',{key:log.logEntryID,staticStyle:{"border-bottom":"1px solid grey","border-top":"1px solid grey"}},[_c('v-card',{staticClass:"log-card my-1",attrs:{"flat":"","color":_vm.getColorByIndex(index)}},[_c('v-card-title',{staticClass:"py-0 pr-3 pl-4"},[_c('div',{staticClass:"log-title-container"},[_c('div',{staticClass:"log-title-item"},[_c('v-checkbox',{attrs:{"input-value":_vm.logIsSelected(log),"color":"secondary"},on:{"change":(v) => _vm.selectLog(log)}})],1),_c('div',{staticClass:"log-title-item ml-1",staticStyle:{"flex-grow":"2"}},[_c('h3',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"logs_title",on:{"click":(v) =>
                      isExpanded(log) ? expand(log, !v) : expand(log, v)}},[_c('span',[_vm._v(_vm._s(log.time))]),_vm._v(" "+_vm._s(log.districts)+": "+_vm._s(log.title)),_c('br'),_vm._v("@ "+_vm._s(log.location)),_c('br'),(log.casenum.length > 0)?_c('span',[_vm._v("Case: "+_vm._s(log.casenum))]):_vm._e()])])])]),(isExpanded(log))?_c('v-card-text',{staticClass:"pr-3 pl-4",staticStyle:{"padding-bottom":"0"}},[_c('Transition',{attrs:{"name":"fade-transition","mode":"out-in"}},[(_vm.logs_rendered == true)?_c('div',{staticClass:"logs_data"},[_c('v-row',{attrs:{"dense":"","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[(log.additionalCaseNumbers.length > 0)?_c('div',[_c('span',[_c('strong',[_vm._v("Addt'l Case:")]),_vm._v(" "+_vm._s(log.additionalCaseNumbers)+" ")])]):_vm._e(),_c('div',[(log.sergeant.length > 0)?_c('span',[_c('strong',[_vm._v("Sgt:")]),_vm._v(" "+_vm._s(log.sergeant)+" ")]):_vm._e(),(log.lieutenant.length > 0)?_c('span',[_c('strong',[_vm._v("Lt:")]),_vm._v(" "+_vm._s(log.lieutenant)+" ")]):_vm._e()]),(
                        log.additionalCaseNumbers.length > 0 ||
                        log.sergeant.length > 0 ||
                        log.lieutenant.length > 0
                      )?_c('div',{staticStyle:{"height":"8px"}}):_vm._e()])],1),_c('div',{class:{
                    'log-incident': true,
                    'log-incident-hidden': !isExpanded(log),
                    'log-incident-expanded': isExpanded(log),
                  },domProps:{"innerHTML":_vm._s(log.description)}}),_vm._l((log.partiesInvolved),function(p,pindex){return _c('div',{key:pindex},[_c('div',{staticStyle:{"display":"inline"}},[_c('strong',{staticStyle:{"display":"inline"}},[_vm._v(_vm._s(p.involvement))]),_vm._v(": "+_vm._s(p.firstname)+" "+_vm._s(p.lastname)+" "),(_vm.partieDOBValid(p.dob))?_c('div',{staticStyle:{"display":"inline"}},[_vm._v(" ("+_vm._s(_vm.formatPartieDOB(p.dob))+") ")]):_vm._e()]),(p.description.length > 0)?_c('div',{staticStyle:{"display":"inline"}},[_vm._v(" "+_vm._s(p.description)+" ")]):_vm._e()])}),_c('br'),_c('v-divider'),_c('v-row',{attrs:{"dense":"","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('small',[_vm._v("Entered: "+_vm._s(log.s_createduser)+" "+_vm._s(_vm.formatDateTime(log.s_create)))])]),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('small',[_vm._v("Edited: "+_vm._s(log.s_user)+" "+_vm._s(_vm.formatDateTime(log.s_date)))])])],1)],2):_c('div',[_c('v-skeleton-loader',{attrs:{"type":"paragraph, sentences"}})],1)])],1):_vm._e()],1)],1)})}}]),model:{value:(_vm.selected_logs),callback:function ($$v) {_vm.selected_logs=$$v},expression:"selected_logs"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }