var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{ tiptap_editor: true, 'tiptap_editor-invalid': _vm.invalid },staticStyle:{"margin-top":"5px","margin-bottom":"5px"}},[(_vm.editor)?_c('div',{staticClass:"tiptap_menubar"},[_c('v-btn',{staticClass:"tiptap_menubutton",attrs:{"disabled":!_vm.editor.can().undo(),"light":"","color":"black","text":"","outlined":"","small":""},on:{"click":function($event){_vm.editor.chain().focus().undo().run()}}},[_c('v-icon',[_vm._v("$undo")])],1),_c('v-btn',{staticClass:"tiptap_menubutton",attrs:{"disabled":!_vm.editor.can().redo(),"light":"","color":"black","text":"","outlined":"","small":""},on:{"click":function($event){_vm.editor.chain().focus().redo().run()}}},[_c('v-icon',[_vm._v("$redo")])],1),_c('v-divider',{staticClass:"mx-2 tiptap-divider",attrs:{"vertical":"","light":""}}),_c('v-btn',{class:{
        tiptap_menubutton: true,
        'tiptap_menubutton-active': _vm.editor.isActive('bold'),
      },attrs:{"light":"","color":"black","text":"","outlined":"","small":""},on:{"click":function($event){_vm.editor.chain().focus().toggleBold().run()}}},[_c('v-icon',[_vm._v("$bold")])],1),_c('v-btn',{class:{
        tiptap_menubutton: true,
        'tiptap_menubutton-active': _vm.editor.isActive('italic'),
      },attrs:{"light":"","color":"black","text":"","outlined":"","small":""},on:{"click":function($event){_vm.editor.chain().focus().toggleItalic().run()}}},[_c('v-icon',[_vm._v("$italic")])],1),_c('v-btn',{class:{
        tiptap_menubutton: true,
        'tiptap_menubutton-active': _vm.editor.isActive('underline'),
      },attrs:{"light":"","color":"black","text":"","outlined":"","small":""},on:{"click":function($event){_vm.editor.chain().focus().toggleUnderline().run()}}},[_c('v-icon',[_vm._v("$underline")])],1),_c('v-divider',{staticClass:"mx-2 tiptap-divider",attrs:{"vertical":"","light":""}}),_c('v-btn',{class:{
        tiptap_menubutton: true,
        'tiptap_menubutton-active': _vm.editor.isActive('bulletList'),
      },attrs:{"light":"","color":"black","text":"","outlined":"","small":""},on:{"click":function($event){_vm.editor.chain().focus().toggleBulletList().run()}}},[_c('v-icon',[_vm._v("$ul")])],1),_c('v-btn',{class:{
        tiptap_menubutton: true,
        'tiptap_menubutton-active': _vm.editor.isActive('orderedList'),
      },attrs:{"light":"","color":"black","text":"","outlined":"","small":""},on:{"click":function($event){_vm.editor.chain().focus().toggleOrderedList().run()}}},[_c('v-icon',[_vm._v("$ol")])],1)],1):_vm._e(),_c('hr',{staticStyle:{"margin":"0"}}),_c('editor-content',{staticClass:"tiptap_editorbody",attrs:{"editor":_vm.editor,"id":_vm.uniqueID,"spellcheck":"true"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }