
/* eslint-disable */
//BUS DICE BAG 1,9
//Copyright(c)  2019 Beta Universe LLC/ John Molt

export const bus_common = {

    methods: {
        swall_diag: function (title, text, icon, callback_yes, buttonTxt, cancelTxt, cancel_callback, custom_target) {
            //this.show_loader(false);
            if (!buttonTxt) {
                buttonTxt = "Yes";
            }
            if (!cancelTxt) {
                cancelTxt = "No";
            }

            if (!custom_target) {
                custom_target = "body";
            }
            else {
                custom_target = document.getElementById(custom_target)
            }

            /*      this.$store.commit('setSwal_on', true);*/
            this.$swal({
                backdrop: true,
                title: title,
                html: text,
                icon: icon,
                confirmButtonText: buttonTxt,
                background: 'var(--v-backdrop-lighten1)',
                confirmButtonColor: 'var(--v-secondary-darken2)',
                showCancelButton: true,
                cancelButtonText: cancelTxt,
                target: custom_target,
                position: 'center',
                onClose: () => {
                    /*this.$store.commit('setSwal_on', false);*/

                }
            }).then((result) => {

                if (result.value) {
                    if (callback_yes) {
                        callback_yes();
                    }

                } else if (result.dismiss === "cancel") {
                    if (cancel_callback) {
                        cancel_callback();
                    }

                }
            });
        },
        swall_it: function (title, text, icon, callback, button, custom_target) {
            this.show_loader(false);
            if (!button) {
                button = "OK";
            }
            if (!custom_target) {
                custom_target = "body";
            }
            else {
                custom_target = document.getElementById(custom_target)
            }

            /*this.$store.commit('setSwal_on', true);*/
            this.$swal({
                backdrop: true,
                title: title,
                html: text,
                background: 'var(--v-backdrop-lighten1)',
                confirmButtonColor: 'var(--v-secondary-darken2)',
                icon: icon,
                confirmButtonText: button,
                target: custom_target,
                position: 'center',

            }).then(() => {

                if (callback) {
                    callback();
                }


            });
        },
        hande_swal_error: function (error, custom_target) {

            let error_custom_target = "";
            if (!custom_target) {
                error_custom_target = "body";
            }
            else {
                error_custom_target = document.getElementById(error_custom_target)
            }

            let callback = () => {
                window.location.replace(this.$store.state.loginURL);
            };
            if (error.response) {
                if (
                    error.response.data.msg.includes("redirected to the login page")
                ) {
                    this.$swal({
                        title: "Error!",
                        text: error.response.data.msg,
                        icon: "error",
                        confirmButtonText: "OK",
                        confirmButtonColor: "#283593",
                        timer: 4000,
                        target: error_custom_target,
                        didClose: callback,
                    });
                } else {
                    this.swall_it(
                        "Error!",
                        error.response.data.msg,
                        "error",
                        null,
                        null,
                        custom_target
                    );
                }
            }
        },
        show_loader: function (value) {
            if (value) {
                this.$store.commit('setSwal_on', true);
                this.$store.commit('setShow_loader', true);
            } else {
                this.$store.commit('setSwal_on', false);
                this.$store.commit('setShow_loader', false);
            }
        },
        resetTimer: function () {
            this.$store.commit("setTime_remaining_warn_mills", this.$store.state.default_time_remaining_warn_mills);
        },
        date_is_valid: function (value) {
            var aDate = this.$moment(value, 'YYYY-MM-DD', true);
            var isValid = aDate.isValid();
            return isValid;
        },
        emailIsValid: function (email) {
            return /\S+@\S+\.\S+/.test(email)
        },
        format_date_us: function (dt) {
            return this.$moment(dt).format("MM/DD/YYYY");
        },
        format_date_sql: function (dt) {
            return this.$moment(dt).format("YYYY-MM-DD");
        },
        isDateBefore(dtFrm, dtTo) {
            return this.$moment(dtFrm).isBefore(dtTo);
        },
        isphonenumber: function (inputtxt) {
            //XXX-XXX-XXXX
            //XXX.XXX.XXXX
            //XXX XXX XXXX
            var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
            if (inputtxt.match(phoneno)) {
                return true;
            }
            else {

                return false;
            }
        },
        reflow: function (elm_str) {
            if (elm_str == null) {
                elm_str = "app";
            }
            var elt = document.getElementById(elm_str);
            console.log(elt.offsetHeight);

            document.body.style.transform = 'none;';
            setTimeout(function () {
                document.body.style.transform = 'scale(' + "1" + ')';
            }, 200);

        },

        isNumber: function (n) {
            return !isNaN(parseFloat(n)) && isFinite(n);
        },

        mno: function (obj) {
            //make new object  used no all the time
            return JSON.parse(JSON.stringify(obj))
        }
    }
}
