import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import {
    mdiEye, mdiEyeOff, mdiCheck, mdiCheckDecagram, mdiCheckDecagramOutline, mdiCancel, mdiClose, mdiCheckboxBlankOutline, mdiCheckboxMarked, mdiCloseCircle, mdiMenuDown, mdiMenu,
    mdiArrowLeft, mdiArrowRight, mdiSend, mdiCheckboxMarkedCircleOutline, mdiMagnify, mdiViewDashboardOutline, mdiAccountEditOutline, mdiLogout, mdiAccount, mdiInformationOutline,
    mdiTextBoxSearch, mdiTextBoxSearchOutline, mdiExitToApp, mdiClipboardOutline, mdiAccountBox, mdiHome, mdiAccountCircle, mdiChevronUp,
    mdiChevronDown, mdiPlay, mdiRestore, mdiStop, mdiContentSave, mdiCloseCircleOutline, mdiPencil, mdiDelete, mdiCheckboxMarkedCircle, mdiPlus, mdiChevronRight, mdiChevronLeft,
    mdiArrowUp, mdiStar, mdiStarOutline, mdiFileDocument, mdiEmail, mdiImagePlus, mdiFile, mdiContentSaveAll, mdiFileMultiple,
    mdiUndo, mdiRedo, mdiFormatBold, mdiFormatItalic, mdiFormatUnderline, mdiFormatHeader1, mdiFormatHeader2, mdiFormatHeader3, mdiFormatListBulleted, mdiFormatListNumbered,
    mdiPoliceBadge, mdiCalendarMonth, mdiKeyboardReturn, mdiMinusBox, mdiHelpCircle, mdiPrinterEye, mdiCheckboxMultipleBlankOutline, mdiCheckboxMultipleMarked, mdiCheckboxMultipleBlank, mdiPrinter, mdiWindowClose, mdiPlusCircle, mdiSwapHorizontal, mdiRadioboxBlank, mdiRadioboxMarked,
    mdiDotsVertical, mdiTrayArrowDown, mdiContentCopy, mdiNewspaperVariantOutline, mdiNewspaperVariantMultipleOutline, mdiCloseBoxOutline
} from '@mdi/js'

//Usage '$vuetify.icons.xbox' or $xbox
const MY_ICONS = {
    // jm added
    plus: mdiPlus,
    cancel1: mdiCloseCircleOutline,
    save: mdiContentSave,
    stop: mdiStop,
    loop: mdiRestore,
    play: mdiPlay,
    chevup: mdiChevronUp,
    chevdown: mdiChevronDown,
    person: mdiAccountCircle,
    home: mdiHome,
    checkedcircle: mdiCheckboxMarkedCircle,
    /*Default List*/
    eye: mdiEye,
    eyeoff: mdiEyeOff,
    complete: mdiCheck,
    cancel: mdiCancel,
    close: mdiClose,
    delete: mdiCloseCircle, // delete (e.g. v-chip close)
    del: mdiDelete,
    clear: mdiClose,
    success: mdiCheck,
    successOutline: mdiCheckDecagramOutline,
    successBold: mdiCheckDecagram,
    info: mdiInformationOutline,
    warning: '...',
    error: '...',
    prev: mdiChevronLeft,
    next: mdiChevronRight,
    checkboxOn: mdiCheckboxMarked,
    checkboxOff: mdiCheckboxBlankOutline,
    checkboxIndeterminate: mdiMinusBox,
    delimiter: '...', // for carousel
    sort: mdiArrowUp,
    expand: mdiMenuDown,
    menu: mdiMenu,
    subgroup: '...',
    dropdown: mdiMenuDown,
    radioOn: mdiRadioboxMarked,
    radioOff: mdiRadioboxBlank,
    edit: mdiPencil,
    ratingEmpty: '...',
    ratingFull: '...',
    ratingHalf: '...',
    loading: '...',
    first: '...',
    last: '...',
    unfold: '...',
    file: '...',
    /*Additional Icons*/
    arrowLeft: mdiArrowLeft,
    arrowRight: mdiArrowRight,
    send: mdiSend,
    checkboxMarkedOutline: mdiCheckboxMarkedCircleOutline,
    magnify: mdiMagnify,
    search: mdiMagnify,
    appViewDash: mdiViewDashboardOutline,
    accountEdit: mdiAccountEditOutline,
    logout: mdiLogout,
    account: mdiAccount,
    textSearch: mdiTextBoxSearch,
    textSearchOutline: mdiTextBoxSearchOutline,
    exitToApp: mdiExitToApp,
    clipboard: mdiClipboardOutline,
    email: mdiEmail,
    fileDocument: mdiFileDocument,
    pencil: mdiPencil,
    starOutline: mdiStarOutline,
    star: mdiStar,
    imagePlus: mdiImagePlus,
    draft: mdiFile,
    saveall: mdiContentSaveAll,
    draftall: mdiFileMultiple,
    undo: mdiUndo,
    redo: mdiRedo,
    bold: mdiFormatBold,
    italic: mdiFormatItalic,
    underline: mdiFormatUnderline,
    h1: mdiFormatHeader1,
    h2: mdiFormatHeader2,
    h3: mdiFormatHeader3,
    ul: mdiFormatListBulleted,
    ol: mdiFormatListNumbered,
    policebadge: mdiPoliceBadge,
    calendar: mdiCalendarMonth,
    return: mdiKeyboardReturn,
    help: mdiHelpCircle,
    printeye: mdiPrinterEye,
    print: mdiPrinter,
    checkalloff: mdiCheckboxMultipleBlankOutline,
    checkallon: mdiCheckboxMultipleMarked,
    checkallsome: mdiCheckboxMultipleBlank,
    windowClose: mdiWindowClose,
    plusCircle: mdiPlusCircle,
    swap: mdiSwapHorizontal,
    threedots: mdiDotsVertical,
    download: mdiTrayArrowDown,
    copy: mdiContentCopy,
    news: mdiNewspaperVariantOutline,
    newsall: mdiNewspaperVariantMultipleOutline,
    remove: mdiCloseBoxOutline,


}


Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        values: MY_ICONS,
    },
    theme: {
        dark: true,
        themes: {
            dark: {
                background: '#0D1117',
                primary: '#FFFFFF',
                secondary: '#22F1BF',
                accent: '#7371FF',
                error: '#f44336',
                warning: '#ff9800',
                info: '#03f4ce',
                success: '#4caf50',
                backdrop: '#21262D',
                darkerbackdrop: '#1d2126'
                //background-color: var(--v-accent-lighten2)
            },
        },
        options: {
            customProperties: true
        },
    },
});
