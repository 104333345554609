<template>
  <!--Uses tiptap version 2.0.3-->
  <div
    :class="{ tiptap_editor: true, 'tiptap_editor-invalid': invalid }"
    style="margin-top: 5px; margin-bottom: 5px"
  >
    <!-- <v-btn @click="focusInput">focus</v-btn> -->
    <!--Editor menu-->
    <div v-if="editor" class="tiptap_menubar">
      <v-btn
        @click="editor.chain().focus().undo().run()"
        :disabled="!editor.can().undo()"
        light
        color="black"
        text
        outlined
        small
        class="tiptap_menubutton"
      >
        <v-icon>$undo</v-icon>
      </v-btn>

      <v-btn
        @click="editor.chain().focus().redo().run()"
        :disabled="!editor.can().redo()"
        light
        color="black"
        text
        outlined
        small
        class="tiptap_menubutton"
      >
        <v-icon>$redo</v-icon>
      </v-btn>

      <v-divider vertical class="mx-2 tiptap-divider" light></v-divider>

      <v-btn
        @click="editor.chain().focus().toggleBold().run()"
        :class="{
          tiptap_menubutton: true,
          'tiptap_menubutton-active': editor.isActive('bold'),
        }"
        light
        color="black"
        text
        outlined
        small
      >
        <v-icon>$bold</v-icon>
      </v-btn>

      <v-btn
        @click="editor.chain().focus().toggleItalic().run()"
        :class="{
          tiptap_menubutton: true,
          'tiptap_menubutton-active': editor.isActive('italic'),
        }"
        light
        color="black"
        text
        outlined
        small
      >
        <v-icon>$italic</v-icon>
      </v-btn>

      <v-btn
        @click="editor.chain().focus().toggleUnderline().run()"
        :class="{
          tiptap_menubutton: true,
          'tiptap_menubutton-active': editor.isActive('underline'),
        }"
        light
        color="black"
        text
        outlined
        small
      >
        <v-icon>$underline</v-icon>
      </v-btn>

      <!-- <v-divider vertical class="mx-2 tiptap-divider" light></v-divider>

      <v-btn
        @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
        :class="{
          tiptap_menubutton: true,
          'tiptap_menubutton-active': editor.isActive('heading', { level: 1 }),
        }"
        light
        color="black"
        text
        outlined
        small
      >
        <v-icon>$h1</v-icon>
      </v-btn>

      <v-btn
        @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
        :class="{
          tiptap_menubutton: true,
          'tiptap_menubutton-active': editor.isActive('heading', { level: 2 }),
        }"
        light
        color="black"
        text
        outlined
        small
      >
        <v-icon>$h2</v-icon>
      </v-btn>

      <v-btn
        @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
        :class="{
          tiptap_menubutton: true,
          'tiptap_menubutton-active': editor.isActive('heading', { level: 3 }),
        }"
        light
        color="black"
        text
        outlined
        small
      >
        <v-icon>$h3</v-icon>
      </v-btn> -->

      <v-divider vertical class="mx-2 tiptap-divider" light></v-divider>

      <v-btn
        @click="editor.chain().focus().toggleBulletList().run()"
        :class="{
          tiptap_menubutton: true,
          'tiptap_menubutton-active': editor.isActive('bulletList'),
        }"
        light
        color="black"
        text
        outlined
        small
      >
        <v-icon>$ul</v-icon>
      </v-btn>

      <v-btn
        @click="editor.chain().focus().toggleOrderedList().run()"
        :class="{
          tiptap_menubutton: true,
          'tiptap_menubutton-active': editor.isActive('orderedList'),
        }"
        light
        color="black"
        text
        outlined
        small
      >
        <v-icon>$ol</v-icon>
      </v-btn>
    </div>

    <hr style="margin: 0" />
    <editor-content
      :editor="editor"
      class="tiptap_editorbody"
      :id="uniqueID"
      spellcheck="true"
    />
    <!--<div v-if="editor" class="countertext">{{ editor.storage.characterCount.characters({ mode: 'nodeSize' }) }}</div>-->
  </div>
</template>

<style lang="scss" scoped>
.tiptap_editor {
  border: 0.5px solid #b6b6b6;
  background-color: white;
  border-radius: 4px;
  caret-color: black;
}

.tiptap_editor-invalid {
  border: 0.5px solid red;
}

.countertext {
  padding: 5px;
  font: small-caption;
  color: #000000a1;
}

/*Editor menu bar (buttons)*/
.tiptap_menubar {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 3px;
  padding: 2px;
  width: 100%;
  row-gap: 5px;
  border-radius: 4px;
}

.tiptap_menubutton-active {
  color: var(--v-secondary-darken2) !important;
}

.editor__menuicons {
  align-self: center;
  font-size: large;
}

/*Editor body*/
.tiptap_editorbody {
  color: black;
  padding: 5px;
  border: 3px solid var(--border-color);
  border-radius: 5px;
  width: 100%;
  max-height: 500px;
  overflow-y: scroll;
  min-height: 175px;
  resize: vertical;
  line-height: 1.2;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
}

/*Changes made stop counter css overriding*/
.tiptap_editor::v-deep .ProseMirror strong {
  font-size: inherit;
}

.tiptap_editor::v-deep .ProseMirror p {
  margin-bottom: 0.3rem;
  max-width: none !important;
}

.tiptap_editor::v-deep .ProseMirror:focus {
  outline: none;
}

.tiptap_editor::v-deep .ProseMirror h3 {
  margin-bottom: 0.3rem;
  max-width: none !important;
}

.tiptap_editor::v-deep .ProseMirror h2 {
  margin-bottom: 0.3rem;
  max-width: none !important;
}

.tiptap_editor::v-deep .ProseMirror h1 {
  margin-bottom: 0.3rem;
  max-width: none !important;
}
/*Changes end*/

@media only screen and (max-width: 461px) {
  .tiptap_menubutton {
    width: 20% !important;
  }

  .tiptap_editor {
    max-width: 100%;
    margin: inherit;
  }

  .tiptap-divider {
    display: none;
  }
}
</style>


<script>
import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import CharacterCount from "@tiptap/extension-character-count";

export default {
  props: {
    characterLimit: Number,
    htmlLimit: Number,
    uniqueID: String,
  },

  components: {
    EditorContent,
  },

  data() {
    return {
      editor: null,
      invalid: false,
    };
  },

  watch: {},

  mounted() {
    this.initEditor();
  },

  methods: {
    initEditor() {
      const element1 = document.getElementById(this.uniqueID);
      element1.addEventListener("click", this.handleBodyClick);

      this.editor = new Editor({
        content: this.$attrs.value,
        extensions: [
          StarterKit,
          Underline,
          CharacterCount.configure({
            limit: this.characterLimit,
            mode: "nodeSize",
          }),
        ],

        onUpdate: () => {
          // HTML
          if (this.timeout) clearTimeout(this.timeout);

          this.timeout = setTimeout(() => {
            // TO DO: Potentially add a warning if the user exceeds a certain length of the HTML
            let html = this.editor.getHTML();
            this.$emit("input", html);
          }, 500); // delay

          // JSON
          // this.$emit('update:modelValue', this.editor.getJSON())
        },
      });
    },

    destroyEditor() {
      if (this.timeout) clearTimeout(this.timeout);
      this.editor.destroy();

      const element1 = document.getElementById(this.uniqueID);
      element1.removeEventListener("click", this.handleBodyClick);
    },

    setContent(value) {
      // The true parameter will emit the update event which will update our model as seen above
      this.editor.commands.setContent(value, true);
    },

    appendContent(value) {
      this.editor.commands.insertContent(value);
    },

    //This function can be used to re-init the editor
    refreshEditor() {
      this.destroyEditor();
      this.initEditor();
    },

    focusInput() {
      this.editor.commands.focus();
    },

    handleBodyClick(event) {
      if (event.target.classList.contains("tiptap_editorbody")) {
        // Execute your function here
        this.focusInput();
      }
    },
  },

  beforeUnmount() {
    this.destroyEditor();
  },
};
</script>