<template>
  <v-container id="home-divisions-container">
    <v-app-bar
      elevation="4"
      dark
      class="backdrop lighten-1 division-app-bar"
      style="z-index: 2"
      v-if="this.isMobile"
    >
      <v-app-bar-nav-icon
        @click="openNavigationDrawer"
        v-if="this.isMobile"
      ></v-app-bar-nav-icon>
      <v-toolbar-title class="text-uppercase" v-if="divisionObj"
        >{{ divisionObj.displayName }} Logs</v-toolbar-title
      >
      <v-skeleton-loader v-else type="text"></v-skeleton-loader>
    </v-app-bar>

    <div class="division-row">
      <!-- Navigation Drawer -->
      <div class="nav-col">
        <div
          :class="{
            'divison-navigation-drawer': true,
            'divison-navigation-drawer-hidden': drawerHiddenTemporaryStyle,
          }"
        >
          <v-navigation-drawer
            v-model="drawer"
            :app="isMobile"
            :permanent="!isMobile"
            floating
            :width="drawerWidth"
            mobile-breakpoint="1000"
            class="backdrop"
          >
            <v-container
              class="backdrop lighten-1"
              elevation="4"
              style="z-index: 2"
            >
              <v-row align="start">
                <v-col
                  align-self="center"
                  cols="4"
                  sm="4"
                  lg="4"
                  v-if="!isMobile"
                >
                  <v-img
                    class=""
                    max-height="100"
                    max-width="100"
                    eager
                    contain
                    @click="goHome()"
                    src="../images/blackwhite_badge_supermin4.webp"
                    transition="fade-transition"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-col>

                <v-col align-self="center" cols="3" sm="3" lg="3" v-else>
                  <v-btn @click="closeNavigationDrawer()" icon>
                    <v-icon>$vuetify.icons.menu</v-icon>
                  </v-btn>
                </v-col>
                <v-col align-self="center">
                  <h3 class="text-uppercase">
                    EverWatch
                    <h4 class="text-uppercase" v-if="divisionObj">
                      {{ divisionObj.displayName }} Logs
                    </h4>
                    <v-skeleton-loader v-else type="text"></v-skeleton-loader>
                  </h3>
                </v-col>
              </v-row>
            </v-container>

            <v-list nav dense class="pa-4">
              <v-list-item-group v-model="drawerSelection" color="primary">
                <v-btn
                  elevation="2"
                  v-if="$store.state.EWCanAdd"
                  class="secondary black--text mb-4 mt-2"
                  @click="newLog()"
                >
                  <v-icon left> $plus </v-icon>
                  New
                </v-btn>

                <v-text-field
                  v-model="search_form.search_term"
                  color="secondary"
                  clearable
                  class="mb-4 mt-1"
                  filled
                  outlined
                  dark
                  dense
                  hide-details
                  @keydown.enter="submitSearch()"
                  append-icon="$send"
                  @click:append="submitSearch()"
                  label="Search logs"
                >
                </v-text-field>

                <!--Calendar days buttons-->
                <v-list-group value="none" prepend-icon="$calendar" no-action>
                  <template v-slot:activator>
                    <v-list-item-title>View Last</v-list-item-title>
                  </template>

                  <v-list-item value="last5days">
                    <v-list-item-icon
                      ><v-icon>$calendar</v-icon></v-list-item-icon
                    >

                    <v-list-item-content>
                      <v-list-item-title>5 days</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item value="last15days">
                    <v-list-item-icon
                      ><v-icon>$calendar</v-icon></v-list-item-icon
                    >

                    <v-list-item-content>
                      <v-list-item-title>15 days</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item value="last30days">
                    <v-list-item-icon
                      ><v-icon>$calendar</v-icon></v-list-item-icon
                    >

                    <v-list-item-content>
                      <v-list-item-title>30 days</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>

                <v-divider color="grey" class="my-4"></v-divider>

                <!--Divison Navigation buttons-->
                <v-list-group
                  :value="divisionMenuList"
                  prepend-icon="$swap"
                  no-action
                >
                  <template v-slot:activator>
                    <v-list-item-title>Switch Division</v-list-item-title>
                  </template>

                  <v-list-item
                    v-for="(d, index) in divisions_list_copy"
                    :key="index"
                    :value="`divisionNav_` + d.name"
                    @click="goToDivision(d.name)"
                  >
                    <v-list-item-icon><v-icon>$swap</v-icon></v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title>{{ d.displayName }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>

                <v-divider color="grey" class="my-4"></v-divider>

                <!--Navigation buttons-->
                <!-- <v-list-group
                  :value="goToMenuList"
                  prepend-icon="$return"
                  no-action
                >
                  <template v-slot:activator>
                    <v-list-item-title>Go to</v-list-item-title>
                  </template>

                  <v-list-item value="navHome" @click="goHome()">
                    <v-list-item-icon
                      ><v-icon>$return</v-icon></v-list-item-icon
                    >

                    <v-list-item-content>
                      <v-list-item-title>Home</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item value="navATLS" @click="goSearch()">
                    <v-list-item-icon
                      ><v-icon>$return</v-icon></v-list-item-icon
                    >

                    <v-list-item-content>
                      <v-list-item-title>Search</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item value="navATLS" @click="goHome()">
                    <v-list-item-icon
                      ><v-icon>$return</v-icon></v-list-item-icon
                    >

                    <v-list-item-content>
                      <v-list-item-title>ATLs</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                </v-list-group> -->
              </v-list-item-group>
            </v-list>

            <template v-slot:append>
              <div class="pa-2">
                <v-btn text block color="secondary" @click="logOut()">
                  Logout
                </v-btn>
              </div>
            </template>
          </v-navigation-drawer>
        </div>
      </div>

      <!-- Logs list collapsibles -->
      <div class="logs-col">
        <div class="logs-list-container">
          <logsgroup
            ref="LogGroup"
            :AllSelectedLogIds="AllSelectedLogIds"
            :log_groups="log_groups"
            @logSelectionChange="logSelectionsChanged"
            @lazyRenderLogs="lazyRenderLogs"
          ></logsgroup>

          <h3
            v-if="log_groups.length == 0 && !this.loading"
            style="text-align: center"
          >
            No {{ divisionObj.displayName }} logs found from the last
            {{ lastSelectedDays }} days
          </h3>
        </div>
      </div>

      <!-- Log FABS -->
      <logsfabs
        :AllSelectedLogIds="AllSelectedLogIds"
        :persistentAdd="true"
        @editlogs="editlogs()"
        @addlog="newLog()"
        @deletelog="deletelog()"
        @printLogs="printLogs()"
        @emailLogs="openEmailLogsDialog()"
        @editWebDetails="openWebDialog()"
        @openHelp="openHelpDialog()"
        @copyLogs="openCopyLogsDialog()"
      ></logsfabs>
    </div>

    <!-- Logs Dialog -->
    <logsdialog
      v-if="logs_dialog"
      @closeDialog="closeLogsDialog"
      :mode="logs_dialog_mode"
      :logDivisionID="divisionObj.logDivisionID"
      :logEntryIDs="logs_dialog_logEntryIDs"
    ></logsdialog>

    <!-- Logs Web Dialog -->
    <logsWebDialog
      v-if="logs_web_dialog"
      @closeDialog="closeWebDialog"
      :logEntryIDs="logs_dialog_logEntryIDs"
    ></logsWebDialog>

    <!-- Logs Email Dialog -->
    <logsemaildialog
      v-if="logs_email_dialog"
      @closeDialog="closeLogsEmailDialog"
      :logEntryIDs="logs_dialog_logEntryIDs"
    ></logsemaildialog>

    <!-- Logs Copy Dialog -->
    <logsCopyDialog
      v-if="logs_copy_dialog"
      @closeDialog="closeLogsCopyDialog"
      :logEntryIDs="logs_dialog_logEntryIDs"
    ></logsCopyDialog>

    <!-- Help dialog -->
    <helpDialog
      v-if="help_dialog"
      helpID="43ab0b3e-0c42-41dd-bb9b-2a0d57c92f33"
      @closeDialog="closeHelpDialog()"
    >
    </helpDialog>

    <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        :size="80"
        :width="7"
        color="secondary"
      ></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<style lang="scss" scoped>
#home-divisions-container {
  max-width: 1200px;
  padding: 0px !important;
}

.divison-navigation-drawer {
  max-height: 85vh;
  height: 85vh !important;
}

.divison-navigation-drawer-hidden {
  display: none;
}

.logs-list-container {
  max-height: 85vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.division-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: flex-start;
  margin: 10px;
}

.nav-col {
  width: 25%;
  margin-right: 10px;
}

.logs-col {
  width: 74%;
}

.division-app-bar {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 1px !important;
  border-bottom: 1px solid grey;
}

/*Drawer hidden CSS conditons*/
@media only screen and (max-width: 1000px) {
  .divison-navigation-drawer {
    height: 100% !important;
  }

  .logs-list-container {
    padding-top: 10px;
    max-height: 93vh;
    padding-bottom: 80px;
  }

  .nav-col {
    width: unset;
    margin-right: 0px;
  }

  .logs-col {
    width: 100%;
  }

  .division-row {
    margin-top: 0px;
  }

  .division-app-bar {
    margin-left: 0px;
    margin-right: 0px;
  }
}
</style>

<script>
/* eslint-disable */
import { bus_common } from "../mixins/bus_common";
import logsdialog from "./logs/logs_dialog.vue";
import logsgroup from "./logs/logs_group.vue";
import logsfabs from "./logs/logs_fabs.vue";
import logsemaildialog from "./logs/logs_email_dialog.vue";
const helpDialog = () => import("../components/help_dialog.vue");
const logsCopyDialog = () => import("./logs/logs_copy_dialog.vue");
const logsWebDialog = () => import("./logs/logs_web_dialog.vue");
export default {
  name: "home-division",
  mixins: [bus_common],
  components: {
    logsdialog,
    logsgroup,
    logsfabs,
    logsemaildialog,
    helpDialog,
    logsCopyDialog,
    logsWebDialog,
  },
  data() {
    return {
      divisionObj: null,
      loading: true,
      loading_division: true,
      isMobile: false,
      drawerSelection: "last15days",
      drawer: null,
      drawerHiddenTemporaryStyle: false,
      logs_search: "",
      logsdata: [],
      log_groups: [],
      const_log_groups: null,
      AllSelectedLogIds: [],
      menuFAB: null,
      logs_dialog: false,
      logs_dialog_mode: "new",
      logs_dialog_logEntryIDs: [],
      logs_email_dialog: false,
      logs_copy_dialog: false,
      logs_web_dialog: false,
      goToMenuList: false,
      divisionMenuList: false,
      divisions_list_copy: [{ displayname: "test" }], // Needs a temp record while rendering, later thrown away
      lastSelectedDays_copy: null, //If we select another menu item and get logs, we can lose the value; this keeps a copy
      help_dialog: false,
      search_form: {
        logtype: "ALL",
        search_term: "",
        logEntryIDs: [],
        start_date: "",
        end_date: "",
      },
    };
  },
  methods: {
    // General component functions
    async init() {
      if (this.divisions_list) {
        await this.checkIfParamIsValid();
        await this.getDivisionLogs();
      }

      window.addEventListener("resize", this.onResize, { passive: true });
    },

    async checkIfParamIsValid() {
      let division = this.$route.params.division;
      if (division == undefined) {
        this.$router.push({ name: "not-found" });
      }

      if (this.divisions_list) {
        this.divisionObj = this.LookupDivision(division);
        // After verifying, make a copy of the division list to render nav buttons
        this.divisions_list_copy = this.ArrayWithoutElement(
          this.divisions_list,
          this.divisionObj
        );
      } else {
        this.$router.push({ name: "not-found" });
      }
    },

    async getDivisionLogs() {
      this.loading = true;
      this.AllSelectedLogIds = [];

      // Copy current logs to create a backup
      let logsCopy = JSON.parse(JSON.stringify(this.log_groups));

      var form = {
        token: this.$store.state.user.token,
        logDivisionID: this.divisionObj.logDivisionID,
        lastDays: this.lastSelectedDays,
      };

      await this.$axios
        .post("/api/logs/GetByDivision", form)
        .then((response) => {
          this.log_groups = response.data;
          // Lazy load the data of the last opened panel
          this.$refs.LogGroup.LazyLoadOpenedPanel();
          this.loading = false;

          if (this.const_log_groups != null) {
            this.const_log_groups = JSON.parse(JSON.stringify(this.log_groups));
          }
        })
        .catch((error) => {
          this.log_groups = logsCopy;
          this.loading = false;
          this.hande_swal_error(error, null);
        });
    },

    LookupDivision(divisionName) {
      for (let index = 0; index < this.divisions_list.length; index++) {
        const element = this.divisions_list[index];
        if (element.name == divisionName) {
          this.loading_division = false;
          return element;
        }
      }
      this.$router.push({ name: "not-found" });
    },

    ArrayWithoutElement(originalArray, elementToExclude) {
      // Use the filter method to create a new array excluding the specified element
      const newArray = originalArray.filter(
        (element) => element !== elementToExclude
      );

      return newArray;
    },

    onResize() {
      this.isMobile = window.innerWidth < 1000;
    },

    // Drawer functions
    openNavigationDrawer() {
      this.drawer = true;
    },
    closeNavigationDrawer() {
      this.drawer = false;
    },
    DrawerAction() {
      switch (this.mode) {
        case "new":
          this.initNewMode();
          break;
        case "edit":
          this.initEditMode();
          break;
        default:
          this.initNewMode();
          break;
      }
    },
    clearSelectedLogs() {
      for (let i = 0; i < this.log_groups.length; i++) {
        this.log_groups[i].logs_selected = [];
      }
      this.AllSelectedLogIds = [];
    },

    //Due to lazy loading this has been changed and is no longer used
    SearchLogs() {
      // Prior to searching the first time, set a copy of the current const_log_groups
      this.clearSelectedLogs();
      if (this.const_log_groups == null) {
        this.const_log_groups = JSON.parse(JSON.stringify(this.log_groups));
      }

      // Each time we search, we search using the const log groups
      // But set the data to the runtime group
      for (let i = 0; i < this.const_log_groups.length; i++) {
        const const_group = this.const_log_groups[i];
        const runtime_group = this.log_groups[i];
        runtime_group.logs = this.searchArrayObjects(
          const_group.logs,
          this.logs_search
        );
      }

      // On mobile, auto close the drawer afte searching
      if (this.isMobile && this.drawer) {
        this.closeNavigationDrawer();
      }
    },
    searchArrayObjects(arr, searchTerm) {
      // Searches each nested object from the array provided and returns the object
      // If logs have a nested object in the future this might need to be tweaked so not search nested objects
      // Could return the incorrect object if so
      // Thanks ChatGPT lol
      const results = [];

      function searchObjects(obj) {
        for (const key in obj) {
          if (typeof obj[key] === "object" && obj[key] !== null) {
            searchObjects(obj[key]); // Recursively search nested objects
          } else if (
            String(obj[key]).toLowerCase().includes(searchTerm.toLowerCase())
          ) {
            results.push(obj);
            break; // Break the loop if a match is found in the current object
          }
        }
      }

      arr.forEach((element) => {
        if (typeof element === "object" && element !== null) {
          searchObjects(element);
        }
      });

      return results;
    },
    clearSearch() {
      // When cleaing search, reset selected logs and re-copy data
      this.clearSelectedLogs();
      for (let i = 0; i < this.const_log_groups.length; i++) {
        const const_group = this.const_log_groups[i];
        const runtime_group = this.log_groups[i];
        runtime_group.logs = const_group.logs;
      }
      this.const_log_groups = null;

      // On mobile, auto close the drawer afte searching
      if (this.isMobile && this.drawer) {
        this.closeNavigationDrawer();
      }
    },
    goHome() {
      var qry_token = this.$route.query.token;
      this.$router.push({ name: "home-logs", query: { token: qry_token } });
    },
    goSearch() {
      var qry_token = this.$route.query.token;
      this.$router.push({ name: "logs-search", query: { token: qry_token } });
    },
    goToDivision(name) {
      var qry_token = this.$route.query.token;
      this.$router.push({
        name: "home-divisions",
        params: { division: name },
        query: { token: qry_token },
      });
    },

    // log groups func
    logSelectionsChanged(data) {
      this.AllSelectedLogIds = data;
    },
    async lazyRenderLogs(i) {
      if (this.log_groups[i].logs_rendered == false) {
        //console.log("Lazy render data now ...");

        // In order to lazy render the data, we must get all IDs in this group
        let logIds = this.getPropertyValues(
          this.log_groups[i].logs,
          "logEntryID"
        );

        var form = {
          token: this.$store.state.user.token,
          logEntryIDs: logIds,
          s_del: "f",
          dateGrouping: false,
        };

        await this.$axios
          .post("/api/logs/GetByList", form)
          .then((response) => {
            const fieldsToCopy = ["description", "partiesInvolved"];
            this.copyGroupFields(i, response.data, fieldsToCopy);
            this.log_groups[i].logs_rendered = true;
          })
          .catch((error) => {
            this.hande_swal_error(error, this.swal_target);
            this.log_groups[i].logs_rendered = false;
          });
      } else {
        // console.log("Lazy data already rendered skipping ....");
      }
    },
    getPropertyValues(arr, propertyName) {
      // Check if the array is not empty and the property name is provided
      if (!arr || arr.length === 0 || !propertyName) {
        console.error("Invalid input");
        return [];
      }

      // Use map to extract the specified property directly
      return arr.map((obj) => obj[propertyName] || 0);
    },
    copyGroupFields(i, dataArray, fieldsToCopy) {
      // For each log we get back, let's copy the fields over
      for (let j = 0; j < dataArray.length; j++) {
        const logToCopy = dataArray[j];

        // Find the index of the matching log
        let logIndex = this.log_groups[i].logs.findIndex(
          (obj) => obj.logEntryID === logToCopy.logEntryID
        );

        fieldsToCopy.forEach((field) => {
          this.log_groups[i].logs[logIndex][field] = logToCopy[field];
        });
      }
    },

    // Logs Dialog
    openLogsDialog() {
      this.logs_dialog = true;
    },
    closeLogsDialog() {
      this.logs_dialog = false;
      this.getDivisionLogs();
    },
    newLog() {
      this.logs_dialog_mode = "new";
      this.openLogsDialog();
    },
    editlogs() {
      this.logs_dialog_mode = "edit";
      this.logs_dialog_logEntryIDs = this.AllSelectedLogIds;
      this.openLogsDialog();
      setTimeout(() => {
        this.clearSelectedLogs();
      }, 300);
    },
    printLogs() {
      var LogEntries = this.integerArrayToString(this.AllSelectedLogIds);
      var qry_token = this.$route.query.token;
      this.$router.push({
        name: "logs-print",
        query: { logEntries: LogEntries, token: qry_token },
      });
    },
    async deletelog() {
      // Kinda a mess
      this.loading = true;

      // We can only delete a single record
      let LogIDToDelete = this.AllSelectedLogIds[0];
      // Find the log record based on the selected id
      var LogToDelete = this.getLogEntry(LogIDToDelete);
      if (LogToDelete == null) {
        this.loading = false;
        return;
      }
      // Set all s_Del to t
      LogToDelete.s_del = "t";
      for (let i = 0; i < LogToDelete.partiesInvolved.length; i++) {
        LogToDelete.partiesInvolved[i].s_del = "t";
      }

      var form = { token: this.$store.state.user.token, logEntry: LogToDelete };
      await this.$axios
        .post("/api/logs/SaveLog", form)
        .then((response) => {
          this.swall_it(
            "Saved!",
            "The log entry was successfully deleted.",
            "success",
            null,
            null,
            null
          );
          this.getDivisionLogs();
        })
        .catch((error) => {
          this.loading = false;
          this.hande_swal_error(error, null);
        });
    },

    getLogEntry(logEntryID) {
      for (const group of this.log_groups) {
        for (const log of group.logs) {
          if (log.logEntryID == logEntryID) {
            return log;
          }
        }
      }

      return null;
    },
    logOut() {
      let callback = () => {
        window.location.replace(this.$store.state.loginURL);
      };
      this.swall_diag(
        "Log Out?",
        "Are you sure you would like to log out?",
        "question",
        callback,
        "Yes",
        "No",
        null,
        null
      );
    },
    integerArrayToString(array) {
      // Filter out any non-integer values and convert the integers to strings.
      const idStrings = array
        .filter((value) => Number.isInteger(value))
        .map((value) => value.toString());

      // Join the array of strings with commas to create the final string.
      const resultString = idStrings.join(",");

      return resultString;
    },
    getRandomNumber() {
      return Math.floor(Math.random() * 1000) + 1;
    },

    // Web details dialog functions
    openWebDialog() {
      this.logs_dialog_logEntryIDs = this.AllSelectedLogIds;
      this.logs_web_dialog = true;
      setTimeout(() => {
        this.clearSelectedLogs();
      }, 300);
    },

    closeWebDialog() {
      this.logs_web_dialog = false;
      this.getDivisionLogs();
    },

    // Email Logs Dialog functions
    openEmailLogsDialog() {
      this.logs_dialog_logEntryIDs = this.AllSelectedLogIds;
      this.logs_email_dialog = true;
      setTimeout(() => {
        this.clearSelectedLogs();
      }, 300);
    },
    closeLogsEmailDialog() {
      this.logs_email_dialog = false;
      this.getDivisionLogs();
    },

    // Copy Logs Dialog functions
    openCopyLogsDialog() {
      this.logs_dialog_logEntryIDs = this.AllSelectedLogIds;
      this.logs_copy_dialog = true;
      setTimeout(() => {
        this.clearSelectedLogs();
      }, 300);
    },
    closeLogsCopyDialog() {
      this.logs_copy_dialog = false;
      this.getDivisionLogs();
    },

    openHelpDialog() {
      this.help_dialog = true;
    },

    closeHelpDialog() {
      this.help_dialog = false;
    },

    submitSearch() {
      this.search_form.logtype = this.divisionObj.displayName;
      var qry_token = this.$route.query.token;
      this.$router.push({
        name: "logs-search",
        params: { prop_search_form: this.search_form },
        query: { token: qry_token },
      });
    },
  },

  computed: {
    drawerWidth() {
      return window.innerWidth - 100;
    },

    divisions_list() {
      if (this.$store.state.logDivisions.length > 0) {
        return this.$store.state.logDivisions;
      } else {
        return null;
      }
    },

    lastSelectedDays() {
      var val = null;
      switch (this.drawerSelection) {
        case "last5days":
          val = 5;
          this.lastSelectedDays_copy = val;
          break;
        case "last15days":
          val = 15;
          this.lastSelectedDays_copy = val;
          break;
        case "last30days":
          val = 30;
          this.lastSelectedDays_copy = val;
          break;
      }
      if (val == null) return this.lastSelectedDays_copy;
      else return val;
    },
  },

  watch: {
    "$store.state.logDivisions": function () {
      this.checkIfParamIsValid();
      this.getDivisionLogs();
    },
    drawerSelection: function () {
      if (
        this.drawerSelection == "last5days" ||
        this.drawerSelection == "last15days" ||
        this.drawerSelection == "last30days"
      ) {
        this.getDivisionLogs();
      }
    },
  },

  created() {
    this.isMobile = window.innerWidth < 1000;
    if (window.innerWidth < 1000) {
      this.drawerHiddenTemporaryStyle = true;
      setTimeout(() => {
        this.drawerHiddenTemporaryStyle = false;
      }, 500);
    }
  },

  beforeDestroy() {
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize, { passive: true });
  },

  mounted() {
    this.onResize();
    this.$nextTick(() => {
      this.init();
    });
  },
};
</script>
