<template>
  <transition-group name="fade-transition">
    <!-- main container -->
    <v-container
      class="cmpbk"
      id="audit-logs-container"
      :key="1"
      style="margin-bottom: 40px"
    >
      <v-app-bar
        elevation="4"
        dark
        class="backdrop lighten-1"
        height="60px"
        style="z-index: 2"
        extension-height="75px"
      >
        <v-spacer />
        <v-toolbar-title> Audit of EverWatch Logs </v-toolbar-title>
        <v-spacer />

        <template v-slot:extension>
          <div style="width: 100%">
            <v-text-field
              v-model="search_form.search_term"
              color="secondary"
              clearable
              filled
              outlined
              dark
              dense
              hide-details
              @keydown.enter="getSearchData"
              append-icon="$send"
              @click:append="getSearchData"
              label="Search by Email"
              ref="search_field"
              prepend-inner-icon="$magnify"
            >
              <template v-slot:append-outer>
                <v-tooltip bottom :disabled="$vuetify.breakpoint.xs">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      light
                      class="px-4"
                      @click="openSearchDialog()"
                      color="white"
                      v-bind="attrs"
                      v-on="on"
                      style="top: -12px"
                    >
                      <v-icon v-if="search_filters_text.length == 0" size="30"
                        >$textSearch</v-icon
                      >
                      <v-icon v-else size="30" color="secondary"
                        >$textSearch</v-icon
                      >
                    </v-btn>
                  </template>
                  <span>Use Advanced Search</span>
                </v-tooltip>
              </template>
            </v-text-field>
            <div style="margin-top: -20px; min-height: 40px">
              <div
                v-if="search_filters_text.length > 0"
                class="pa-2 d-flex text--accent-1"
              >
                <div
                  v-if="search_filters_text.length == 1"
                  class="d-inline-flex"
                  style="max-width: 100%; flex-wrap: wrap"
                >
                  <div
                    v-for="(text, index) in search_filters_text"
                    :key="index"
                    class="mr-2 text-caption"
                  >
                    {{ text.title }}
                    <span class="filtertext">({{ text.value }})</span>
                  </div>
                </div>

                <div
                  v-else
                  class="d-inline-flex"
                  style="max-width: 100%; flex-wrap: wrap"
                >
                  <div
                    v-for="(text, index) in search_filters_text"
                    :key="index"
                    class="mr-2 text-caption"
                  >
                    {{ text.title }}
                    <span class="filtertext mr-1">({{ text.value }}) </span> |
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </v-app-bar>

      <v-card
        class="transparent pa-3"
        style="min-height: 600px; box-shadow: none !important"
        elevation="2"
      >
        <v-expansion-panels v-model="openedPanel">
          <v-expansion-panel
            v-for="(result, i) in search_result"
            class="backdrop"
            :key="i"
          >
            <v-expansion-panel-header>
              <v-row>
                <div class="white--text" style="line-height: 1.1">
                  <strong>{{ formatDate(result.s_date) }}</strong>
                  <br v-if="isMobile" />
                  by
                  <div style="display: inline-block; font-style: italic">
                    {{ result.user_email }}
                  </div>

                  <br v-if="isMobile" />
                  <div v-else style="display: inline-flex">&nbsp;&#8212;</div>

                  <strong> Type :</strong>
                  {{ result.event_type }}
                </div>
              </v-row>
            </v-expansion-panel-header>

            <v-expansion-panel-content class="backdrop lighten-2 pa-2">
              <ul>
                <li v-for="(log, l) in result.logEntries" :key="l">
                  {{ formatDate2(log.date) }} {{ log.time }} {{ log.location }}
                  {{ LookupDivisionName(log.logEntryID_LogDivisionID) }}
                </li>
              </ul>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <h3
          v-if="search_result.length == 0"
          style="text-align: center"
          class="mt-12"
        >
          No search results found
        </h3>
      </v-card>
    </v-container>

    <div :key="2">
      <!-- Admin Search Dialog -->
      <adminDialog
        v-if="admin_dialog"
        :search_form="search_form"
        @closeDialog="closeSearchDialog()"
        @filterTextUpdated="searchFiltersUpdated"
        @applyFilters="applySearchFilter()"
        @resetFilters="resetSearchFilters()"
      ></adminDialog>
    </div>

    <v-overlay :key="3" :value="overlay_loading">
      <v-progress-circular
        indeterminate
        :size="80"
        :width="7"
        color="secondary"
      ></v-progress-circular>
    </v-overlay>

    <div class="fab-container" :key="4">
      <v-fab-transition>
        <v-btn
          color="secondary black--text"
          dark
          fab
          @click="goHome()"
          class="mb-6"
        >
          <v-icon> $home </v-icon>
        </v-btn>
      </v-fab-transition>
    </div>
  </transition-group>
</template>

<style lang="scss" scoped>
@import "../../scss/site.scss";

#audit-logs-container {
  max-width: 1100px;
}
.fab-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  column-gap: 15px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 2;
}
.filtertext {
  color: var(--v-secondary-base);
}
</style>

<script>
/* eslint-disable */
import { bus_common } from "../../mixins/bus_common";
import adminDialog from "./logs_admin_dialog.vue";

export default {
  name: "logs_admin",
  mixins: [bus_common],
  components: { adminDialog },
  props: {},
  data() {
    return {
      const_search_form: {
        search_term: "",
        start_date: "",
        end_date: "",
      },
      search_form: {
        search_term: "",
        start_date: "",
        end_date: "",
      },

      search_filters_text: [],
      admin_dialog: false,
      openedPanel: null,

      search_result: [],
      overlay_loading: true,
    };
  },
  methods: {
    init() {
      this.generate_filter_text();
      this.getSearchData();
    },

    // Search Dialog functions
    openSearchDialog() {
      this.admin_dialog = true;
      // Icon, re-focuses search bar, this prevents that
      this.$refs.search_field.blur();
    },
    closeSearchDialog() {
      this.admin_dialog = false;
      // Icon, re-focuses search bar, this prevents that
      this.$refs.search_field.blur();
    },
    searchFiltersUpdated(data) {
      this.search_filters_text = data;
    },
    applySearchFilter() {
      this.closeSearchDialog();
      this.getSearchData();
    },
    resetSearchFilters() {
      this.search_form = JSON.parse(JSON.stringify(this.const_search_form));
    },
    generate_filter_text() {
      let filters_active_text = [];

      if (
        this.search_form.start_date != "" ||
        this.search_form.end_date != ""
      ) {
        let sdate = "mm/dd/yyyy";
        let edate = "mm/dd/yyyy";

        if (this.search_form.start_date != "")
          sdate = this.$moment(
            new Date(this.search_form.start_date.replace(/-/g, "/"))
          ).format("MM/DD/YYYY");
        if (this.search_form.end_date != "")
          edate = this.$moment(
            new Date(this.search_form.end_date.replace(/-/g, "/"))
          ).format("MM/DD/YYYY");

        let filter_text = { title: "DATE RANGE", value: sdate + " - " + edate };
        filters_active_text.push(filter_text);
      }
      this.searchFiltersUpdated(filters_active_text);
    },

    async getSearchData() {
      this.overlay_loading = true;
      let search_copy = this.search_form;
      if (search_copy.start_date == "") {
        search_copy.start_date = "1900-01-01";
      }
      if (search_copy.end_date == "") {
        search_copy.end_date = "1900-01-01";
      }
      var form = {
        token: this.$store.state.user.token,
        search_term: search_copy.search_term,
        start_date: search_copy.start_date,
        end_date: search_copy.end_date,
      };
      await this.$axios
        .post("/api/logs/SearchRecordedLogs", form)
        .then((response) => {
          this.search_result = response.data;
          this.overlay_loading = false;
        })
        .catch((error) => {
          this.hande_swal_error(error, this.swal_target);
          this.overlay_loading = false;
        });
    },
    formatDate(date) {
      return this.$moment(new Date(date)).format("MM/DD/YYYY hh:mm:ss A");
    },
    formatDate2(date) {
      return this.$moment(new Date(date)).format("MM/DD/YYYY");
    },
    LookupDivisionName(divisionID) {
      if (this.divisions_list) {
        for (let index = 0; index < this.divisions_list.length; index++) {
          const element = this.divisions_list[index];
          if (element.logDivisionID == divisionID) {
            return element.displayName;
          }
        }
      }
      return "Division Not Found";
    },
    goHome() {
      var qry_token = this.$route.query.token;
      this.$router.push({ name: "home-logs", query: { token: qry_token } });
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$store.state.user.token != "") {
        this.init();
      }
    });
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    },
    divisions_list() {
      return this.$store.state.logDivisions;
    },
  },
  watch: {
    "$store.state.user.token": function () {
      if (this.$store.state.user.token != "") {
        this.init();
      }
    },
  },
};
</script>
