<template>
  <v-container style="max-width: none">
    <v-data-iterator
      :items="logs_data"
      :no-results-text="'No logs found'"
      :no-data-text="'No logs found'"
      v-model="selected_logs"
      item-key="logEntryID"
      hide-default-footer
      :expanded.sync="ExpandedItems"
      disable-pagination
    >
      <template v-slot:default="{ items, isExpanded, expand }">
        <v-row
          v-for="(log, index) in items"
          :key="log.logEntryID"
          style="border-bottom: 1px solid grey; border-top: 1px solid grey"
        >
          <v-card flat class="log-card my-1" :color="getColorByIndex(index)">
            <v-card-title class="py-0 pr-3 pl-4">
              <div class="log-title-container">
                <div class="log-title-item">
                  <v-checkbox
                    :input-value="logIsSelected(log)"
                    color="secondary"
                    class=""
                    @change="(v) => selectLog(log)"
                  >
                    <!-- <template v-slot:label>
                  <h3 class="logs_title ml-1">
                    {{ log.time }} {{ log.districts }} : {{ log.title }} <br />
                    @ {{ log.location }}
                  </h3>
                </template> -->
                  </v-checkbox>
                </div>
                <div class="log-title-item ml-1" style="flex-grow: 2">
                  <h3
                    class="logs_title"
                    v-ripple
                    @click="
                      (v) =>
                        isExpanded(log) ? expand(log, !v) : expand(log, v)
                    "
                  >
                    <span>{{ log.time }}</span> {{ log.districts }}:
                    {{ log.title }}<br />@ {{ log.location }}<br />
                    <span v-if="log.casenum.length > 0"
                      >Case: {{ log.casenum }}</span
                    >
                  </h3>
                </div>
              </div>
            </v-card-title>

            <v-card-text
              v-if="isExpanded(log)"
              class="pr-3 pl-4"
              style="padding-bottom: 0"
            >
              <Transition name="fade-transition" mode="out-in">
                <div class="logs_data" v-if="logs_rendered == true">
                  <v-row dense no-gutters>
                    <v-col cols="12" md="12">
                      <div v-if="log.additionalCaseNumbers.length > 0">
                        <span
                          ><strong>Addt'l Case:</strong>
                          {{ log.additionalCaseNumbers }}
                        </span>
                      </div>
                      <div>
                        <span v-if="log.sergeant.length > 0"
                          ><strong>Sgt:</strong> {{ log.sergeant }}
                        </span>
                        <span v-if="log.lieutenant.length > 0"
                          ><strong>Lt:</strong> {{ log.lieutenant }}
                        </span>
                      </div>
                      <div
                        style="height: 8px"
                        v-if="
                          log.additionalCaseNumbers.length > 0 ||
                          log.sergeant.length > 0 ||
                          log.lieutenant.length > 0
                        "
                      />
                    </v-col>
                  </v-row>

                  <div
                    v-html="log.description"
                    :class="{
                      'log-incident': true,
                      'log-incident-hidden': !isExpanded(log),
                      'log-incident-expanded': isExpanded(log),
                    }"
                  ></div>

                  <div v-for="(p, pindex) in log.partiesInvolved" :key="pindex">
                    <div style="display: inline">
                      <strong style="display: inline">{{
                        p.involvement
                      }}</strong
                      >:
                      {{ p.firstname }}
                      {{ p.lastname }}
                      <div style="display: inline" v-if="partieDOBValid(p.dob)">
                        ({{ formatPartieDOB(p.dob) }})
                      </div>
                    </div>
                    <div
                      style="display: inline"
                      v-if="p.description.length > 0"
                    >
                      {{ p.description }}
                    </div>
                  </div>

                  <br />
                  <v-divider></v-divider>

                  <v-row dense no-gutters>
                    <v-col cols="12" md="6">
                      <small
                        >Entered: {{ log.s_createduser }}
                        {{ formatDateTime(log.s_create) }}</small
                      >
                    </v-col>

                    <v-col cols="12" md="6">
                      <small
                        >Edited: {{ log.s_user }}
                        {{ formatDateTime(log.s_date) }}</small
                      >
                    </v-col>
                  </v-row>
                </div>

                <div v-else>
                  <v-skeleton-loader
                    type="paragraph, sentences"
                  ></v-skeleton-loader>
                </div>
              </Transition>
            </v-card-text>

            <!-- <v-btn
              outlined
              v-if="isExpanded(log)"
              color="secondary"
              class="mx-1 my-2"
              text
              @click="(v) => expand(log, !v)"
              :block="$vuetify.breakpoint.xs"
            >
              Hide Details
            </v-btn>

            <v-btn
              outlined
              v-else
              color="secondary"
              class="mx-1 my-2"
              text
              @click="(v) => expand(log, v)"
              :block="$vuetify.breakpoint.xs"
            >
              Show Details
            </v-btn> -->
          </v-card>
        </v-row>
      </template>
    </v-data-iterator>
  </v-container>
</template>

<style lang="scss" scoped>
.log-card {
  width: 100%;
}

.logs_title {
  color: white;
  font-size: 1rem;
  line-height: 1.25;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
  cursor: pointer;
  white-space: pre-wrap;
  word-break: break-word;
}

.log-incident {
  &.log-incident-hidden {
    // max-height: 45px;
    // overflow: hidden;
    display: none;
  }

  &.log-incident-expanded {
    // max-height: none;
  }
}

.log-incident ::v-deep p {
  margin-bottom: 10px;
}

.log-title-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}

/*.log-incident::after {
        content: "...";
        color: green;
    }*/
</style>

<script>
/* eslint-disable */
import { bus_common } from "../../mixins/bus_common";
export default {
  name: "logs_list",
  mixins: [bus_common],
  props: ["logs_data", "logs_selected", "logs_rendered", "highlightTerm"],
  data() {
    return {
      selected_logs: [],
      ExpandedItems: [],
      highlightRunning: false,
    };
  },
  methods: {
    message1() {
      alert("This button does nothing yet.");
    },
    init() {},
    selectLog(log) {
      const index = this.logs_selected.indexOf(log);
      if (index > -1) {
        // Item exists in the array, so remove it
        this.logs_selected.splice(index, 1);
      } else {
        // Item does not exist in the array, so add it
        this.logs_selected.push(log);
      }

      this.$emit("logSelectionChange");
    },
    logIsSelected(log) {
      return this.logs_selected.includes(log);
    },

    formatDateTime(date) {
      return this.$moment(new Date(date)).format("MM/DD/YYYY HHmm");
    },

    formatPartieDOB(date) {
      let format = this.$moment(new Date(date)).format("MM/DD/YYYY");
      if (format == "01/01/1900") {
        return "";
      } else {
        return format;
      }
    },

    partieDOBValid(date) {
      if (date == "1900-01-01T00:00:00") {
        return false;
      } else {
        return true;
      }
    },

    getColorByIndex(index) {
      const evenColor = "#2a2f35";
      const oddColor = "#32383f";
      // Return the color based on the index
      return index % 2 === 0 ? evenColor : oddColor;
    },

    clearExpandedLogs() {
      this.ExpandedItems = [];
    },

    highlightResults(searchTerm) {
      if (
        searchTerm == "" ||
        searchTerm == undefined ||
        this.highlightRunning ||
        searchTerm.length < 3
      )
        return;
      this.highlightRunning = true;
      //console.log("Running highlight func ...");
      // Get all containers
      var containers = document.querySelectorAll(".logs_data");
      //console.log(containers);
      // Loop through each container
      containers.forEach((container) => {
        // Call the recursive function to search and highlight
        this.highlightInContainer(container, searchTerm);
      });
      this.highlightRunning = false;
    },

    highlightInContainer(element, searchTerm) {
      //console.log(element);
      // Base case: if the element is a text node, search for the term and wrap it with <mark> tags
      if (element.nodeType === Node.TEXT_NODE) {
        var regex = new RegExp("(" + searchTerm + ")", "gi");
        var replacement = element.textContent.replace(regex, "<mark>$1</mark>");
        element.parentNode.innerHTML = element.parentNode.innerHTML.replace(
          element.textContent,
          replacement
        );
      } else if (element.nodeType === Node.ELEMENT_NODE) {
        // Recursive case: if the element is an element node, search its child nodes
        element.childNodes.forEach((child) => {
          //console.log(child);
          this.highlightInContainer(child, searchTerm);
        });
      }
    },
  },
  watch: {
    ExpandedItems: {
      handler(val) {
        if (this.ExpandedItems.length > 0 && this.logs_rendered) {
          //console.log("Search expanded items");

          this.$nextTick(() => {
            this.highlightResults(this.highlightTerm);
          });
        }
      },
    },
    logs_rendered: {
      handler(val) {
        if (this.ExpandedItems.length > 0 && this.logs_rendered) {
          //console.log("Search expanded items");
          this.$nextTick(() => {
            this.highlightResults(this.highlightTerm);
          });
        }
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.init();
    });
  },
};
</script>
